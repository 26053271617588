import Vue from 'vue';
import VueRouter from 'vue-router';
import {
  isUserLoggedIn,
  getUserData,
  getHomeRouteForLoggedInUser,
} from '@/auth/utils';
import { canNavigate } from '@/libs/acl/routeProtection';
import publicCodes from './routes/publicCodes';
import schoolData from './routes/schoolData';
import humanResources from './routes/humanResources';
import employeeMovement from './routes/employeeMovement';
import Settings from './routes/Settings';
import Transactions from './routes/Transactions';
import procedures from './routes/procedures';
import statistics from './routes/statistics';
import stores from './routes/Stores';
import purchases from './routes/purchases';
import items from './routes/items';

// import purchTransactions from './routes/purchTransactions';

Vue.use(VueRouter);

const router = new VueRouter({
  // mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
  routes: [
    ...publicCodes,
    ...schoolData,
    ...humanResources,
    ...employeeMovement,
    ...Settings,
    ...Transactions,
    ...procedures,
    ...statistics,
    ...stores,
    ...purchases,
    ...items,

    {
      path: '/',
      name: 'home',
      component: () => import('@/pages/Dashboard/Edit.vue'),
      meta: {
        pageTitle: 'Home',
        breadcrumb: [
          {
            text: 'Home',
            active: true,
          },
        ],
      },
    },
    {
      path: '/subscription-data',
      name: 'subscriptionData',
      component: () => import('@/pages/SubscriptionData/Edit.vue'),
      meta: {
        pageTitle: 'subscriptionData',
        breadcrumb: [
          {
            text: 'subscriptionData',
            active: true,
          },
        ],
      },
    },
    {
      path: '/second-page',
      name: 'second-page',
      component: () => import('@/views/SecondPage.vue'),
      meta: {
        pageTitle: 'Second Page',
        breadcrumb: [
          {
            text: 'Second Page',
            active: true,
          },
        ],
      },
    },
    {
      path: '/chart-of-account',
      name: 'chartOfAccount',
      component: () => import('@/pages/Accounts/chartOfAccount/List.vue'),
      meta: {
        pageTitle: 'chartOfAccount',
        breadcrumb: [{ text: 'accounts', active: true }],
        permission: 'viewAccounts',
      },
    },
    {
      path: '/cost-center',
      name: 'costCenter',
      component: () => import('@/pages/Accounts/costCenter/List.vue'),
      meta: {
        pageTitle: 'costCenter',
        breadcrumb: [{ text: 'accounts', active: true }],
        permission: 'viewCostCenters',
      },

    },
    {
      path: '/vouchers',
      component: () => import('@/layouts/navigationIndex.vue'),
      children: [
        {
          path: '',
          name: 'vouchers',
          component: () => import('@/pages/Accounts/vouchers/List.vue'),
          meta: {
            pageTitle: 'vouchers',
            breadcrumb: [{ text: 'accounts', active: true }],
            permission: 'viewVouchers',
          },
        },
        {
          path: 'new',
          name: 'vouchers-new',
          component: () => import('@/pages/Accounts/vouchers/Edit.vue'),
          meta: {
            pageTitle: 'vouchersNew',
            breadcrumb: [
              { text: 'accounts', active: true },
              { text: 'vouchers', active: true },
            ],
            permission: 'addVouchers',
          },
        },
        {
          path: 'closedVoucher',
          name: 'closedVoucher-new',
          component: () => import('@/pages/Accounts/vouchers/ClosingVoucher.vue'),
          props: true,
          meta: {
            pageTitle: 'closedVoucher',
            breadcrumb: [
              { text: 'accounts', active: true },
              { text: 'vouchers', active: true },
            ],
            permission: 'addVouchers',
          },
        },
        {
          path: 'edit/:id',
          name: 'vouchers-edit',
          props: true,
          component: () => import('@/pages/Accounts/vouchers/Edit.vue'),
          meta: {
            pageTitle: 'vouchersEdit',
            breadcrumb: [
              { text: 'accounts', active: true },
              { text: 'vouchers', active: true },
            ],
            permission: 'editVouchers',
          },
        },
        {
          path: 'closedVoucherEdit/:id',
          name: 'closedVoucher-edit',
          props: true,
          component: () => import('@/pages/Accounts/vouchers/ClosingVoucher.vue'),
          meta: {
            pageTitle: 'closedVoucher',
            breadcrumb: [
              { text: 'accounts', active: true },
              { text: 'vouchers', active: true },
            ],
            permission: 'editVouchers',
          },
        },
      ],
    },
    {
      path: '/post-vouchers',
      name: 'post-vouchers',
      component: () => import('@/pages/Accounts/vouchers/PostVouchers.vue'),
      meta: {
        pageTitle: 'postVouchers',
        breadcrumb: [
          {
            text: 'accounts',
            active: true,
          },
        ],
        permission: 'gLPost',
      },
    },
    {
      path: '/invoices',
      name: 'invoices',
      component: () => import('@/pages/Sales/List.vue'),
      meta: {
        pageTitle: 'salesInvoices',
        breadcrumb: [{ text: 'sales', active: true },
        ],
        permission: 'viewSalesTransactions',
      },
    },
    {
      path: '/return',
      component: () => import('@/layouts/navigationIndex.vue'),
      children: [
        {
          path: 'new',
          name: 'return-invoice',
          component: () => import('@/pages/Sales/ReturnSales/new.vue'),
          meta: {
            pageTitle: 'newReturnSales',
            breadcrumb: [{ text: 'returnSales', active: true }],
            permission: 'addReturnSalesTransactions',
          },
        },
        {
          path: 'edit/:id',
          name: 'return-invoice-edit',
          props: true,
          component: () => import('@/pages/Sales/ReturnSales/new.vue'),
          meta: {
            pageTitle: 'EditReturnSales',
            breadcrumb: [{ text: 'returnSales', active: true }],
            permission: 'editReturnSalesTransactions',
          },
        },
      ],
    },
    {
      path: '/sales',
      component: () => import('@/layouts/navigationIndex.vue'),
      children: [
        {
          path: 'new',
          name: 'pos-invoice',
          component: () => import('@/pages/Sales/SalesPoints/new.vue'),
          meta: {
            pageTitle: 'salesInvoices',
            breadcrumb: [{ text: 'sales', active: true }],
            permission: 'addSalesTransactions',
          },

        },
        {
          path: 'editSales/:id',
          name: 'pos-invoice-edit',
          props: true,
          component: () => import('@/pages/Sales/SalesPoints/new.vue'),
          meta: {
            pageTitle: 'salesInvoices',
            breadcrumb: [{ text: 'sales', active: true }],
            permission: 'editSalesTransactions',
          },
        },
      ],
    },
    {
      path: '/deservedFees',
      component: () => import('@/layouts/navigationIndex.vue'),
      children: [
        {
          path: '',
          name: 'deservedFees',
          component: () => import('@/pages/Sales/DeservedFees/List.vue'),
          meta: {
            pageTitle: 'deservedFees',
            breadcrumb: [{ text: 'sales', active: true }],
            permission: 'viewDeservedFees',
          },
        },
        {
          path: 'new',
          name: 'deservedFees-new',
          component: () => import('@/pages/Sales/DeservedFees/Edit.vue'),
          meta: {
            pageTitle: 'addDeservedFees',
            breadcrumb: [{ text: 'sales', active: true }],
            permission: 'addDeservedFees',
          },

        },
        {
          path: 'edit/:id',
          name: 'deservedFees-edit',
          props: true,
          component: () => import('@/pages/Sales/DeservedFees/Edit.vue'),
          meta: {
            pageTitle: 'editDeservedFees',
            breadcrumb: [{ text: 'sales', active: true }],
            permission: 'editDeservedFees',
          },
        },
      ],
    },
    {
      path: '/school-setting',
      name: 'school-setting',
      component: () => import('@/pages/Settings/School/Edit.vue'),
      meta: {
        pageTitle: 'school-setting',
        breadcrumb: [{ text: 'Settings', active: true }],
        permission: 'viewSchool',
      },
    },
    {
      path: '/students-reports',
      name: 'students-reports',
      component: () => import('@/pages/Settings/Report/studentsReports.vue'),
      meta: {
        pageTitle: 'students-reports',
        breadcrumb: [
          {
            text: 'reports',
            active: true,
          },
        ],
       // permission: ['reviewStudentsReports', 'reviewStudentsReportsServices', 'reviewStudentsReportsDiscounts']
      },
    },
    {
      path: '/employees-reports',
      name: 'employees-reports',
      component: () => import('@/pages/Settings/Report/employeesReports.vue'),
      meta: {
        pageTitle: 'employees-reports',
        breadcrumb: [
          {
            text: 'reports',
            active: true,
          },
        ],
      },
    },
    {
      path: '/accounts-reports',
      name: 'accounts-reports',
      component: () => import('@/pages/Settings/Report/accountsReports.vue'),
      meta: {
        pageTitle: 'accounts-reports',
        breadcrumb: [
          {
            text: 'reports',
            active: true,
          },
        ],
      },
    },

    {
      path: '/transaction-reports',
      name: 'transaction-reports',
      component: () => import('@/pages/Settings/Report/transactionReport.vue'),
      meta: {
        pageTitle: 'transaction-reports',
        breadcrumb: [
          {
            text: 'reports',
            active: true,
          },
        ],
      },
    },
    {
      path: '/items-reports',
      name: 'items-reports',
      component: () => import('@/pages/Settings/Report/itemsReports.vue'),
      meta: {
        pageTitle: 'items-reports',
        breadcrumb: [
          {
            text: 'reports',
            active: true,
          },
        ],
      },
    },
    {
      path: '/login',
      name: 'login',
      component: () => import('@/pages/UserAccount/Login-v1.vue'),
      meta: { layout: 'full' },
    },
    {
      path: '/change-password',
      name: 'change-password',
      component: () => import('@/pages/Settings/ChangePassword/Edit.vue'),
      meta: {
        pageTitle: 'changePassword',
        breadcrumb: [{ text: 'changePassword', active: true }],
        permission: '',
      },
    },
    {
      path: '/ForgetPassword',
      name: 'forget-password',
      component: () => import('@/pages/UserAccount/ForgotPassword.vue'),
      meta: { layout: 'full' },
    },
    {
      path: '/error-404',
      name: 'error-404',
      component: () => import('@/views/error/Error404.vue'),
      meta: { layout: 'full' },
    },
    { path: '*', redirect: 'error-404' },
    {
      path: '/RegistrationRequests',
      name: 'registration-requests',
      component: () => import('@/pages/Temporary/RegistrationRequests.vue'),
      meta: { layout: 'full' }
    },
    {
      path: '/PayRequest',
      name: 'pay-requests',
      component: () => import('@/pages/Temporary/PayRequest.vue'),
      meta: { layout: 'full' }
    },
    {
      path: '/paymentStatus/:id',
      name: 'payment-status',
      component: () => import('@/pages/Temporary/PaymentStatus.vue'),
      meta: { layout: 'full' }
    }
  ],
});

// controlling un-authorized Routes
const unAutoheroizedRoutes = ['login', 'forget-password', 'registration-requests', 'pay-requests', 'payment-status']
function isCurrentRouteNonAuthurized(route) {
  return unAutoheroizedRoutes.includes(route);
}

router.beforeEach((to, _, next) => {
  const isLoggedIn = isUserLoggedIn();

  // Redirect to login if not logged in
  if (!isLoggedIn && to.name !== 'login' && !isCurrentRouteNonAuthurized(to.name)) return next({ name: 'login' });

  if (!canNavigate(to)) {
    // If logged in => not authorized
    return next({ name: 'error-404' });
  }

  // Redirect if logged in
  if (to.meta.redirectIfLoggedIn && isLoggedIn) {
    const userData = getUserData();
    next(getHomeRouteForLoggedInUser(userData ? userData.role : null));
  }

  return next();
});

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg');
  if (appLoading) {
    appLoading.style.display = 'none';
  }
});

export default router;
